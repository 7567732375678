<script>
    import Social from "./Social.svelte";
</script>

<header class="flex gap-4 flex-wrap place-content-center p-2 relative"
        id="header-logo">
    <a href="/">
        <img class="z-10 h-48 xl:h-60 relative" alt="" src="/assets/img/logo.png" />
    </a>
    <div class="xl:absolute xl:top-2/4 xl:right-20">
        <Social />
    </div>
    <div class="z-10 xl:absolute xl:top-1/4 xl:left-20 xl:w-1/4">
        Ο Γιώργος και ο Δήμος συζητούν για τις συνωμοσίες, τα μυστήρια και όλες
        τις ιδέες που κανείς δε θέλει να παραδεχτεί. Μια βόλτα στα απόκρυφα,
        κάθε εβδομάδα στη Λέσχη των Συνωμοσιών.
    </div>
    <div
        class="absolute w-full h-1 top-40 xl:top-3/4 bg-gradient-to-r from-amber-400 to-fuchsia-700"
    >
        &nbsp;
    </div>
</header>
