<script>
    import { onMount } from "svelte";
    import { Route, router } from "tinro";
    router.mode.history();

    import OpenGraph from "./components/OpenGraph.svelte";
    import Header from "./components/Header.svelte";
    import EpisodeRoute from "./components/EpisodeRoute.svelte";
    import Main from "./components/Main.svelte";
    import Footer from "./components/Footer.svelte";

    import { current_episode, episodes, episode_desc } from "./stores/store.js";

    let promise;
    $: episode = $episodes[$current_episode];

    async function getEpisodes() {
        const res = await fetch("/assets/js/episodes.json");
        const json = await res.json();

        if (res.ok) {
            $episodes = json.songs;
            episode = $episodes[$current_episode];
            return episode;
        } else {
            throw new Error(text);
        }
    }

    onMount(() => {
        promise = getEpisodes();
    });
</script>

{#if episode}
    <OpenGraph {episode} />
{/if}

<Header />
<main class="container mx-auto mt-2">
    {#if episode}
        {#key $router.path}
            <Route path="/*" let:meta>
                <EpisodeRoute url={meta.url} />
            </Route>
        {/key}

        <Main {episode} episode_desc={$episode_desc} />
    {:else}
        <h2>Loading</h2>
    {/if}
</main>

<!-- <Route path="*">
         <NotFound />
         </Route> -->

<Footer />
