<script>
    import { createEventDispatcher } from "svelte";

    export let post;

    const password = localStorage.getItem("#" + post.id);
    const dispatch = createEventDispatcher();
    let error_message = "";

    function dateString() {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
            timeZone: "Europe/Athens",
        };
        return new Intl.DateTimeFormat("el-GR", options).format(
            post.date * 1000
        );
    }

    async function deletePost() {
        const res = await fetch("/api/delete-post", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "text/plain",
            },
            body: JSON.stringify({ id: post.id, password: password }),
        });

        if (res.ok) {
            localStorage.removeItem("#" + post.id);
            dispatch("update");
        } else {
            const json = await res.json();
            error_message = JSON.stringify(json);
        }
    }
</script>

<div
    class="mb-4"
    id={post.id}
>
    <div class="discordia-preample mb-1">
        <span class="text-cyan-500">
            {post.author}
        </span>
        {dateString()}
        <span>No.{post.id}</span>
        {#if password}
            <button class="text-red-500" on:click={deletePost}>delete</button>
        {/if}
        <span>
            {#if post.isRepliedBy}
                {#each post.isRepliedBy as reply}
                    <a href={"#" + post.id}> {">>" + reply} </a>
                {/each}
            {/if}
            <span /></span
        >
    </div>
    <div class="mb-2">
        <p class="max-w-prose whitespace-pre-wrap">
            {post.content}
        </p>
    </div>
</div>
