<footer class="relative">
    <div class="absolute w-full h-1 top-0 bg-gradient-to-r from-amber-400 to-fuchsia-600">
        &nbsp;
    </div>
    <p class="text-primary p-4 flex place-content-center">
        The Conspiracy Club 2023
    </p>
    <p class="text-primary pt-4 flex place-content-center">Εάν θες, μπορείς να στηρίξεις τη δουλειά μας:
        <ol>
            <li class="text-primary flex place-content-center"><a href="https://www.patreon.com/theconspiracyclub">Patreon</a></li>
            <li class="text-primary flex place-content-center"><a href="https://www.buymeacoffee.com/conspiracyclub">Buy me a Coffee</a></li>
        </ol>
        
</footer>
