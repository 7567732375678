<script>
    import { createEventDispatcher } from "svelte";
    import { randomName } from "../util/names.js";

    export let id;

    const dispatch = createEventDispatcher();

    let author = "";
    let content = "";
    let form;
    let error_message = "";
    let sending = false;

    function validateLength() {
        return content.length >= 10;
    }

    async function sendPost() {
        if (!validateLength()) {
            error_message = "το σχόλιο πρέπει να είναι πάνω από 10 χαρακτήρες";
        } else {
            error_message = "";
            sending = true;
            const data = new FormData(form);

            if (data.get("contact")) {
                author = "";
                content = "";
                sending = false;
                return;
            } /* naive honeypot implementation */

            if (!data.get("author")) data.set("author", randomName());

            const password = Math.random().toString(36).slice(-8);
            data.set("password", password);

            const res = await fetch("/api/create-post", {
                method: "POST",
                body: data,
            });

            if (res.ok) {
                const json = await res.json();
                dispatch("update", JSON.stringify(json));
                author = "";
                content = "";
                sending = false;
                localStorage.setItem("#" + json, password);
            } else {
                sending = false;
                error_message = "κάτι πήγε στραβά";
            }
        }
    }
</script>

<form
    bind:this={form}
    id="discordia-form"
    class="opacity-70 grid grid-cols-4 xl:grid-cols-5 gap-1 mb-4"
>
    <input bind:value={id} class="hidden" name="id" type="text" />
    <label
        class="hidden xl:block p-1 bg-neutral shadow-md shadow-black text-primary border-2 border-primary-neutral"
        for="author">Name</label
    >
    <input
        class="col-span-3 p-1 bg-neutral shadow-md shadow-black text-primary border-2 border-primary-neutral focus:border-fuchsia-400"
        name="author"
        bind:value={author}
        type="text"
        placeholder="όνομα (προαιρετικό)"
    />
    <button
        on:click|preventDefault={sendPost}
        class="btn btn-primary shadow-md shadow-black"
        type="submit"
    >
        {#if sending}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;"
                width="24px"
                height="24px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
            >
                <path
                    fill="none"
                    stroke="#d946ef"
                    stroke-width="8"
                    stroke-dasharray="177.0463604736328 79.54256774902345"
                    d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
                    stroke-linecap="round"
                    style="transform:scale(1);transform-origin:50px 50px"
                >
                    <animate
                        attributeName="stroke-dashoffset"
                        repeatCount="indefinite"
                        dur="1s"
                        keyTimes="0;1"
                        values="0;256.58892822265625"
                    />
                </path>
            </svg>
        {:else}
            Post
        {/if}
    </button>
    <label
        class="hidden xl:block p-1 bg-neutral shadow-md shadow-black text-primary border-2 border-primary-neutral"
        for="content">Comment</label
    >
    <textarea
        class="col-span-4 p-1 bg-neutral shadow-md shadow-black text-primary border-2 border-primary-neutral focus:border-fuchsia-400"
        name="content"
        bind:value={content}
        placeholder="σχόλιο"
        cols="35"
        rows="2"
    />
    <input
        type="checkbox"
        name="contact"
        value="1"
        style="display:none !important"
        tabindex="-1"
        autocomplete="off"
    />
    <div class="discordia-form-input" />
</form>

{#if error_message}
    <div class="border-2 p-2 mb-2 border-red-400">
        {error_message}
    </div>
{/if}

<style>
    @supports (-webkit-appearance: none) or (-moz-appearance: none) {
        input[type="checkbox"],
        input[type="radio"] {
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }

    textarea,
    input,
    button {
        outline: none;
    }
</style>
