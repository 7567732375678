<script>
    import { createEventDispatcher } from "svelte";

    export let position = 0;

    const dispatch = createEventDispatcher();
</script>

<div
    on:mousedown={(e) => dispatch("drag", e)}
    on:touchstart|preventDefault={(e) => dispatch("dragTouch", e)}
    class="cursor absolute top-0 h-full w-2 bg-red-500 drop-shadow-2xl shadow-black shadow-inner select-none"
    style="left: {position}%;"
>
    &nbsp
</div>

<style>
    .cursor {
        transition-property: left;
        transition-duration: 100ms;
    }
</style>
