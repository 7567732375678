<script>
    import AudioPlayer from "./AudioPlayer.svelte";
    import EpisodeDesc from "./EpisodeDesc.svelte";
    import EpisodeListing from "./EpisodeListing.svelte";
    import NotFound from "./NotFound.svelte";

    import { not_found } from "../stores/store.js";

    export let episode;
    export let episode_desc;
</script>

<AudioPlayer {episode} />

{#if $not_found}
    <NotFound />
{:else}
    {#if episode_desc}
        <EpisodeDesc
            html={episode.html}
            link={episode.link}
            date={episode.date}
        />
    {/if}
    {#if !episode_desc}
        <EpisodeListing />
    {/if}
{/if}
