<script>
    import ChanPost from "./ChanPost.svelte";

    export let json;
</script>

{#if json}
    {#each json.posts as post}
        <ChanPost {post} on:update />
    {/each}
{/if}
