<script>
    import { tick, onMount } from "svelte";
    import {
        current_episode,
        episode_desc,
        episodes,
        paused,
        current_time,
        not_found,
    } from "../stores/store.js";

    export let url;
    let plainUrl = url.split("?")[0];
    let slug = decodeURI(plainUrl);

    $: number = $episodes ? $episodes[$current_episode].number : nil;

    function storeCurrentTime() {
        if (number) localStorage.setItem(number, $current_time);
    }

    function restoreCurrentTime() {
        const currentTime = localStorage.getItem(number);
        if (number && currentTime) {
            $current_time = currentTime;
        }
    }

    function scrollToTop() {
        let isMobile = window.matchMedia(
            "only screen and (max-width: 760px)"
        ).matches;

        if (isMobile) {
            document.getElementById("audio-player").scrollIntoView({behavior: "smooth"});
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }

    async function update() {
        storeCurrentTime();
        if (slug !== "/") {
            let episode_id = $episodes.findIndex(
                (episode) => episode.link === slug
            );

            if (episode_id !== -1) {
                $current_episode = episode_id;
                $episode_desc = true;
                $paused = true;
                await tick();
                restoreCurrentTime();
                $not_found = false;
            } else {
                $current_episode = 0;
                $not_found = true;
            }
        } else {
            $current_episode = 0;
            $episode_desc = false;
            $paused = true;
            $not_found = false;
        }
        scrollToTop();
    }

    onMount(() => update());
</script>
