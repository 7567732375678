<script>
 import { onMount } from "svelte";

 export let url;
 let canvas;
 let canvasCtx;
 let width;
 let height;

 async function getPeaks() {
     let response = await fetch(url.replace("mp3", "json"));
     let json = await response.json();
     return json;
 }
 const json = getPeaks();

 function createCanvas() {
     canvas.style.width = "100%";
     canvas.style.height = "100%";
     canvas.width = canvas.offsetWidth;
     canvas.height = canvas.offsetHeight;

     width = canvas.width;
     height = canvas.height;

     canvasCtx = canvas.getContext("2d");
 }

 function drawWave(json) {
     let lineWidth = width / json.length;
     let lineHeight;
     let data = json.data;
     let x = 0;

     for (var i = 0; i < json.length; i += 2) {
         lineHeight = Math.abs(data[i++] - data[i]) * height / 2;

         canvasCtx.fillStyle = 'black';
         canvasCtx.fillRect(x,  (height - lineHeight) / 2, lineWidth, lineHeight);

         x += lineWidth + 1;
     }
 }

 onMount(() => {
     createCanvas();
     json.then((peaks) => {
         drawWave(peaks)
     }).catch((e) => {
         console.error("error", e);
     });
 });
</script>

<canvas class="m-0 absolute top-0" bind:this={canvas} />

