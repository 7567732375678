<script>
    import { episodes, current_episode } from "../stores/store.js";

    export let name;
    export let date;
    export let image_url;
    export let number;
    export let link;
</script>

<a href={link}>
    <div
        class="card rounded-none cursor-pointer hover:bg-fuchsia-400 hover:shadow-xl"
        class:bg-fuchsia-400={number === $episodes[$current_episode].number}
    >
        <figure class="p-4 border-2 border-fuchsia-400 h-64">
            <img
                class="max-w-full max-h-full w-full h-full object-contain bg-neutral-focus border-2 border-fuchsia-400 shadow-md shadow-black"
                alt=""
                src={image_url}
            />
        </figure>

        <div class="p-4">
            <h2 class="card-title">
                <div class="badge">{number}</div>
                {name}
            </h2>
            <p class="font-thin">{date}</p>
        </div>
    </div>
</a>

<style>
    h2 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }
</style>
