<script>
    import { onMount, tick } from "svelte";

    import ChanForm from "./ChanForm.svelte";
    import ChanThread from "./ChanThread.svelte";

    export let link;
    let domain = "https://theconspiracyclub.gr";
    let promise;
    let id = null;
    let data = null;
    let poller;

    async function getThread(e = { detail: "" }) {
        const res = await fetch("/api/get-thread", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "text/plain",
                "Cache-Control": "no-cache",
            },
            body: JSON.stringify({ url: encodeURI(domain + link) }),
        });
        const json = await res.json();

        if (res.ok) {
            id = json.id;
            data = json;

            await tick();
            if (e.detail) {
                document
                    .getElementById(e.detail)
                    .scrollIntoView({ behavior: "smooth" });
            }
        } else {
            throw new Error(text);
        }
    }

    function setupPoller() {
        if (poller) {
            clearInterval(poller);
        }
        poller = setInterval(getThread, 20000);
    }

    onMount(() => {
        promise = getThread();
        setupPoller();

        return () => clearInterval(poller);
    });
</script>

<ChanForm bind:id on:update={getThread} />
<ChanThread json={data} on:update={getThread} />
