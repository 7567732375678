import { writable } from 'svelte/store';

export const episodes = writable([]);

export const current_episode = writable(0);

export const episode_desc = writable(false);

export const page = writable(0);

export const paused = writable(true);

export const current_time = writable("")

export const audio_context = writable(new (window.AudioContext || window.webkitAudioContext)());
export const audio_source = writable();

export const filter_string = writable("");
export const pagination_size = writable(8);

export const not_found = writable(false);


