export const names = ["Paul Santorinis",
                      "Donald Trump",
                      "Bob Lazar",
                      "Q",
                      "John Titor",
                      "Αρτέμης Σώρρας",
                      "Ψεκασμένος",
                      "David Icke",
                      "Neo",
                      "Morpheus",
                      "Χαρδαβέλλας",
                      "Admiral Byrd",]

export function randomName() {
    const count = names.length;
    return names[Math.floor(Math.random() * count)];
}
