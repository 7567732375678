<script>
 import { createEventDispatcher } from "svelte";
 export let toggle = false;
 export let inactive = false;
 export let reverse = false;
 
 const dispatch = createEventDispatcher();

 function sendClick() {
     dispatch("click");
 }
</script>

<button
    class="bg-neutral pt-2 pb-2 border-b-2 border-t-2 border-neutral m-1 rounded-full"
    on:click={sendClick}
    class:btn-disabled={inactive}
>
    <div
        class="b h-10 w-10 p-1.5 rounded-full shadow-md shadow-black flex justify-center"
        class:disabled={inactive}
               class:active={reverse ? !toggle : toggle}
    >
        <slot>i</slot>
    </div>
</button>

<style>
 .b {
     background-color: #aeaeae;
     margin: 0 1px 0px 1px;
 }

 .active {
     box-shadow: inset 0px 0px 2px 2px #0e0e0e, 0px 0px 2px 2px #e30aae;
 }

    .disabled {
        filter: contrast(0.5);
    }

    .b:active {
        box-shadow: inset 0px 0px 2px 2px #0e0e0e, 0px 0px 2px 2px #e30aae;
    }
</style>
