<script>
    import { slide } from "svelte/transition";
    import { episode_desc } from "../stores/store.js";
    import Chan from "./Chan.svelte";

    export let html;
    export let link;
    export let date;

    function closeDesc() {
        episode_desc.update((n) => !n);
    }
</script>

<div transition:slide>
    <div class="shadow-inner md:grid md:gap-2 md:grid-cols-2" id="desc">
        <style>
            #desc a {
                text-decoration: underline !important;
            }

            #desc h2 {
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
                color: #2094f3;
            }

            #desc li {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }

            #desc ol {
                list-type: square;
            }
        </style>
        <div>
            <div class="card p-2 md:p-0 md:pt-8 mb-4 min-h-0">
                <p class="text-right pb-2">{date}</p>
                {@html html}
            </div>
            <div
                class="w-full mb-4 btn btn-wide bg-fuchsia-400"
                on:click={closeDesc}
            >
                Αλλα επεισοδια
            </div>
        </div>
        <div>
            <div class="card p-2 md:p-8 md:pr-0 mb-4 min-h-0">
                {#key link}
                    <Chan bind:link />
                {/key}
            </div>
        </div>
    </div>
</div>
