<script>
    export let duration;
    export let currentTime;

 $: formattedCurrentTime = currentTime ? formatTime(currentTime) : "00:00";
    $: formattedDuration = duration ? formatTime(duration) : "00:00";

    function formatTime(seconds) {
        if (duration > 3600) {
            return new Date(1000 * seconds).toISOString().substr(11, 8);
        } else {
            return new Date(1000 * seconds).toISOString().substr(14, 5);
        }
    }
</script>

{formattedCurrentTime} -- {formattedDuration}
