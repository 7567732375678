<div class="grid grid-cols-5 gap-2">
    <a href="/rss-itunes.xml" tinro-ignore>
        <object
            aria-label="rss"
            class="w-8"
            type="image/svg+xml"
            data="/assets/img/rss.svg">Your browser does not support SVGs</object
        >
    </a>
    <a href="https://open.spotify.com/show/7tQjID23pAG4KxRMywmX5D">
        <object
            aria-label="spotify"
            class="w-8 "
            type="image/svg+xml"
            data="/assets/img/spotify.svg"
            >Your browser does not support SVGs</object
        >
    </a>
    <a href="https://podcasts.apple.com/gr/podcast/the-conspiracy-club/id1503985574">
        <object
            aria-label="itunes"
            class="w-8"
            type="image/svg+xml"
            data="/assets/img/itunes.svg">Your browser does not support SVGs</object
                                                                        >
    </a>
    <a href="https://www.facebook.com/cconspiracy51/">
        <object
            aria-label="facebook"
            class="w-8 "
            type="image/svg+xml"
            data="/assets/img/facebook.svg"
            >Your browser does not support SVGs</object
        >
    </a>
    <a href="https://www.instagram.com/conspiracyclub51/">
        <object
            aria-label="instagram"
            class="w-8 "
            type="image/svg+xml"
            data="/assets/img/instagram.svg"
            >Your browser does not support SVGs</object
        >
    </a>
</div>

<style>
    object {
        pointer-events: none;
    }
</style>
