<script>
    export let episode;
</script>

<svelte:head>
    <title>{episode.name + " | "} The Conspiracy Club</title>
    <meta property="og:title" content="The Conspiracy Club" /><meta
        property="og:description"
        content={episode.info}
    /><meta
        property="og:image"
        content={episode.cover_art_url}
    /><meta property="og:url" content={"" + episode.link} />
</svelte:head>
