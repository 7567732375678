<script>
    import Knob from "./Knob.svelte";
    import AudioBars from "./AudioBars.svelte";
    import AudioWave from "./AudioWave.svelte";
    import AudioCursor from "./AudioCursor.svelte";
    import AudioTime from "./AudioTime.svelte";
    import AudioButton from "./AudioButton.svelte";
    import {
        current_episode,
        episodes,
        episode_desc,
        paused,
        current_time,
    } from "../stores/store.js";

    export let episode;

    let audio;
    let visual = true;
    let muted;
    let volume_knob = 50;
    let playback_knob = 100;
    let duration = 0;

    $: cursorPosition = ($current_time / duration) * 100;
    $: volume = volume_knob / 100;
    $: lastEpisode = $current_episode === 0;
    $: firstEpisode = $current_episode === $episodes.length - 1;
    $: playbackRate = playback_knob / 100;
    $: nextLink =
        $current_episode - 1 >= 0 ? $episodes[$current_episode - 1].link : "#";
    $: prevLink =
        $current_episode + 1 <= $episodes.length - 1
            ? $episodes[$current_episode + 1].link
            : "#";

    function playAudio() {
        if ($paused) {
            audio.play();
        } else {
            audio.pause();
        }
    }

    function forwardAudio() {
        audio.currentTime += 15;
    }

    function backwardAudio() {
        audio.currentTime -= 15;
    }

    function toggleDesc() {
        $episode_desc = !$episode_desc;
    }

    function toggleVisual() {
        visual = !visual;
    }

    function setCursorPosition(e) {
        const target = e.target;
        const rect = target.parentNode.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const newTime = (x / rect.width) * duration;
        audio.currentTime = newTime;
    }

    function startDrag(e) {
        const target = e.detail.target;
        const parent = target.parentNode;
        const rect = parent.getBoundingClientRect();
        const padding = 6;
        const moveHandler = (e) => {
            const x = e.clientX - rect.left;
            if (x < 0 || x > rect.width - padding) {
                // out of bounds
            } else {
                const newTime = (x / rect.width) * duration;
                $current_time = newTime;
            }
        };
        document.addEventListener("mousemove", moveHandler);
        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", moveHandler);
        });
    }

    function startDragTouch(e) {
        const target = e.detail.target;
        const parent = target.parentNode;
        const rect = parent.getBoundingClientRect();
        const padding = 6;
        const moveHandler = (e) => {
            const x = e.touches[0].clientX - rect.left;
             if (x < 0 || x > rect.width - padding) {
                // out of bounds
            } else {
                const newTime = (x / rect.width) * duration;
                $current_time = newTime;
            }
        };
        document.addEventListener("touchmove", moveHandler);
        document.addEventListener("touchend", () => {
            document.removeEventListener("touchmove", moveHandler);
        });
    }
</script>

<div
    class="drop-shadow-md shadow-md shadow-blue-500 bg-neutral mb-8 xl:grid xl:grid-cols-3 xl:grid-rows-3 gap-2 h-auto xl:h-80 bg-repeat"
    id="audio-player"
>
    <audio
        id="audio"
        crossorigin="anonymous"
        src={episode.url}
        bind:this={audio}
        bind:currentTime={$current_time}
        bind:muted
        bind:paused={$paused}
        bind:volume
        bind:duration
        bind:playbackRate
    />

    <figure
        class="screen border-2 border-black xl:ml-2 xl:mb-2 xl:mt-2 shadow-inner shadow-black bg-stone-500 h-64 xl:h-auto xl:row-span-4"
    >
        <img
            class="max-w-full w-full h-full object-contain p-2"
            alt="episode cover"
            src={episode.cover_art_url}
        />
    </figure>

    <div
        class="screen bg-stone-500 h-full self-start shadow-inner border-2 mt-2 border-black p-4 shadow-black"
    >
        <p>
            {episode.name}
        </p>
    </div>
    <div class="flex justify-end mr-2 gap-2">
        <div
            class="screen flex-grow block h-16 xl:h-full bg-stone-500 shadow-inner mt-2 border-2 border-black shadow-black self-start xl:align-bottom"
        >
            {#if !$paused && visual}
                <AudioBars bind:audio />
            {/if}
        </div>
        <AudioButton on:click={toggleVisual} toggle={visual}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-activity justify-center self-center"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 12h4l3 8l4 -16l3 8h4" />
            </svg>
        </AudioButton>
    </div>

    <div
        class="screen relative h-16 xl:h-3/4 mt-2 bg-stone-500 self-end shadow-inner border-2 border-black shadow-black col-span-2 flex items-end justify-end xl:mr-2"
        on:mousedown|preventDefault={setCursorPosition}
    >
        {#key episode.url}
            <AudioWave url={episode.url} />
        {/key}
        <AudioCursor
            position={cursorPosition}
            on:drag={startDrag}
            on:dragTouch={startDragTouch}
        />
        <p class:blink={$paused} class="z-10 p-1 pointer-events-none">
            <AudioTime bind:duration bind:currentTime={$current_time} />
        </p>
    </div>

    <div class="flex col-span-2 justify-around p-2 mt-2 flex-wrap">
        <div class="flex">
            <div class="pb-2 pt-2">
                <AudioButton on:click={toggleDesc} toggle={$episode_desc}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-details justify-center self-center"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                            d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                        />
                        <path d="M12 3v16" />
                    </svg>
                </AudioButton>
            </div>
            <a class="pb-2 pt-2" href={episode.url} download>
                <AudioButton>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-player-record justify-center self-center"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="#ef4444"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="7" />
                    </svg>
                </AudioButton>
            </a>
        </div>

        <div class="flex">
            <a class="pb-2 pt-2" href={prevLink}>
                <AudioButton inactive={firstEpisode}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-player-skip-back justify-center self-center"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M20 5v14l-12 -7z" />
                        <line x1="4" y1="5" x2="4" y2="19" />
                    </svg>
                </AudioButton>
            </a>
            <div class="pb-2 pt-2">
                <AudioButton on:click={backwardAudio}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-player-track-prev justify-center self-center"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M21 5v14l-8 -7z" />
                        <path d="M10 5v14l-8 -7z" />
                    </svg>
                </AudioButton>
            </div>
            <div class="pb-2 pt-2">
                <AudioButton
                    on:click={playAudio}
                    toggle={$paused}
                    reverse={true}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-player-play justify-center self-center"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 4v16l13 -8z" />
                    </svg>
                </AudioButton>
            </div>

            <div class="pb-2 pt-2">
                <AudioButton on:click={forwardAudio}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-player-track-next justify-center self-center"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 5v14l8 -7z" />
                        <path d="M14 5v14l8 -7z" />
                    </svg>
                </AudioButton>
            </div>
            <a class="pb-2 pt-2" href={nextLink}>
                <AudioButton inactive={lastEpisode}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-player-skip-forward justify-center self-center"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 5v14l12 -7z" />
                        <line x1="20" y1="5" x2="20" y2="19" />
                    </svg>
                </AudioButton>
            </a>
        </div>

        <div class="flex justify-between m-8 xl:m-0">
            <Knob
                size={50}
                label="volume"
                degrees={260}
                tickCount={15}
                min={0}
                max={100}
                bind:value={volume_knob}
            />
            <Knob
                size={50}
                label="speed"
                degrees={260}
                tickCount={6}
                min={75}
                max={200}
                bind:value={playback_knob}
            />
        </div>
    </div>
</div>

<style>
    .blink {
        -webkit-animation: 1.5s linear infinite condemned_blink_effect; /* for Safari 4.0 - 8.0 */
        animation: 1.5s linear infinite condemned_blink_effect;
    }

    /* for Safari 4.0 - 8.0 */
    @-webkit-keyframes condemned_blink_effect {
        0% {
            visibility: hidden;
        }
        50% {
            visibility: hidden;
        }
        100% {
            visibility: visible;
        }
    }

    @keyframes condemned_blink_effect {
        0% {
            visibility: hidden;
        }
        50% {
            visibility: hidden;
        }
        100% {
            visibility: visible;
        }
    }

    .screen {
        background-image: linear-gradient(
                0deg,
                transparent 24%,
                rgba(255, 255, 255, 0.05) 25%,
                rgba(255, 255, 255, 0.05) 26%,
                transparent 27%,
                transparent 74%,
                rgba(255, 255, 255, 0.05) 75%,
                rgba(255, 255, 255, 0.05) 76%,
                transparent 77%,
                transparent
            ),
            linear-gradient(
                90deg,
                transparent 24%,
                rgba(255, 255, 255, 0.05) 25%,
                rgba(255, 255, 255, 0.05) 26%,
                transparent 27%,
                transparent 74%,
                rgba(255, 255, 255, 0.05) 75%,
                rgba(255, 255, 255, 0.05) 76%,
                transparent 77%,
                transparent
            );
        background-size: 50px 50px;
    }
</style>
