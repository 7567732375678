<script>
    import { onMount } from "svelte";

    export let size;
    export let degrees;
    export let min;
    export let max;
    export let tickCount;
    export let value;
    export let label;

    let ticks = [];

    let startingAngle = (360 - degrees) / 2;
    $: endingAngle = startingAngle + degrees;
    $: margin = size * 0.15;
    let currentAngle = 0;

    $: rotation =
        "rotate(" + (currentAngle ? currentAngle : startingAngle) + "deg)";

    function getTicks() {
        let ticks = [];
        const arc = degrees / tickCount;
        const tick_size = margin + size / 2;
        for (let deg = startingAngle; deg <= endingAngle; deg += arc) {
            const tick = {
                deg: deg,
                style: `
                    height: ${tick_size + 10}px;
                    left: ${tick_size - 1}px;
                    top: ${tick_size + 2}px;
                    transform: ${"rotate(" + deg + "deg)"};
                    transform-origin: center top 0px;
                `,
            };
            ticks.push(tick);
        }
        return ticks;
    }

    function startDrag(e) {
        const knob = e.target.getBoundingClientRect();
        const pts = {
            x: knob.left + knob.width / 2,
            y: knob.top + knob.height / 2,
        };
        const moveHandler = (e) => {
            currentAngle = getDeg(e.clientX, e.clientY, pts);
            if (currentAngle === startingAngle) currentAngle--;
            let newValue = Math.floor(
                convertRange(startingAngle, endingAngle, min, max, currentAngle)
            );
            value = newValue;
        };
        document.addEventListener("mousemove", moveHandler);
        document.addEventListener("mouseup", (e) => {
            document.removeEventListener("mousemove", moveHandler);
        });
    }

    function startDragTouch(e) {
        const knob = e.target.getBoundingClientRect();
        const pts = {
            x: knob.left + knob.width / 2,
            y: knob.top + knob.height / 2,
        };
        const moveHandler = (e) => {
            currentAngle = getDeg(
                e.touches[0].clientX,
                e.touches[0].clientY,
                pts
            );
            if (currentAngle === startingAngle) currentAngle--;
            let newValue = Math.floor(
                convertRange(startingAngle, endingAngle, min, max, currentAngle)
            );
            value = newValue;
        };
        document.addEventListener("touchmove", moveHandler);
        document.addEventListener("touchend", (_e) => {
            document.removeEventListener("touchmove", moveHandler);
        });
    }

    function getDeg(cX, cY, pts) {
        const x = cX - pts.x;
        const y = cY - pts.y;
        let deg = (Math.atan(y / x) * 180) / Math.PI;
        if ((x < 0 && y >= 0) || (x < 0 && y < 0)) {
            deg += 90;
        } else {
            deg += 270;
        }
        let finalDeg = Math.min(Math.max(startingAngle, deg), endingAngle);
        return finalDeg;
    }

    function convertRange(oldMin, oldMax, newMin, newMax, oldValue) {
        return Math.max(
            min,
            ((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) +
                newMin
        );
    }

    onMount(() => {
        currentAngle = convertRange(
            min,
            max,
            startingAngle,
            endingAngle,
            value
        );
        ticks = getTicks();
    });
</script>

<div
    class="relative mr-8 ml-8"
    style="height:{size + 'px'}; width:{size + 'px'}"
>
    {#each ticks as tick}
        <div
            class="tick"
            class:active={tick.deg <= currentAngle}
            style={tick.style}
        />
    {/each}
    <div
        class="knob relative"
        style="height:{size + 'px'}; width:{size + 'px'}; margin:{margin +
            'px'}; margin-top:{margin + 2 + 'px'}"
        on:mousedown|preventDefault={startDrag}
        on:touchstart|preventDefault={startDragTouch}
    >
        <div
            class="inner"
            style="height:{size + 'px'}; width:{size +
                'px'}; transform:{rotation};"
        >
            <div class="grip" />
        </div>
    </div>
    <div
        class="label text-xs font-thin uppercase text-center select-none absolute left-2/4 p-0"
        style="margin-left:{margin + 2 + 'px'};"
    >
        {label}
    </div>
</div>

<style>
    .tick {
        position: absolute;
        background: black;
        box-shadow: inset 0 0 0 0 black;
        width: 3px;
        transition: box-shadow 0.5s;
    }

    .knob {
        border-radius: 50%;
        border: 1px black;
        background-color: #aeaeae;
        box-shadow: 0 4px 6px -1px #000, 0 2px 4px -2px #000, 0 0 0 10px #333333;
    }

    .inner {
        border-radius: 50%;
    }

    .grip {
        position: absolute;
        width: 5%;
        height: 5%;
        bottom: 7%;
        left: 50%;
        border-radius: 50%;
        transform: translateX(-50%);
        background: #369;
        box-shadow: 0 0 3px 1px black;
    }

    .active {
        box-shadow: inset 0 0 5px 2px #509eec, 0 0 0 1px #369;
    }

    .label {
        transform: translateX(-50%);
    }
</style>
