<script>
    import { audio_source, audio_context } from "../stores/store.js";
    import { onMount } from "svelte";

    export let audio;

    let analyser = $audio_context.createAnalyser();
    let bufferLength;
    let dataArray;
    let canvas;
    let canvasCtx;
    let width;
    let height;
    let drawVisual;

    function setupAnalyser() {
        if (!$audio_source)
            $audio_source = $audio_context.createMediaElementSource(audio);

        try {
            $audio_context.resume();
        } catch (e) {
            console.log(e);
        }
        
        $audio_source.connect(analyser);
        $audio_source.connect($audio_context.destination);
        analyser.fftSize = 128;
        bufferLength = analyser.frequencyBinCount * 2 / 3;
    }

    function updateFreqData() {
        let arr = new Uint8Array(bufferLength);
        analyser.getByteFrequencyData(arr);
        dataArray = arr;
    }

    function createCanvas() {
        canvas.style.width = "100%";
        canvas.style.height = "100%";
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;

        width = canvas.width;
        height = canvas.height;

        canvasCtx = canvas.getContext("2d");
    }

    function draw() {
        requestAnimationFrame(draw);

        updateFreqData();
        canvasCtx.clearRect(0, 0, width, height);

        var barWidth = width / bufferLength;
        var barHeight;
        var x = 0;

        for (var i = 0; i < bufferLength; i++) {
            barHeight = dataArray[i] * 0.75;

            canvasCtx.fillStyle = "rgb(" + (barHeight + 100) + ",50, 100)";
            canvasCtx.fillRect(x, height - barHeight / 2, barWidth, barHeight);

            x += barWidth + 1;
        }
    }

    onMount(() => {
        setupAnalyser();
        createCanvas();
        draw();
    });
</script>

<canvas class="m-0" bind:this={canvas} />
