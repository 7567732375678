<script>
    import { onMount } from "svelte";
    import EpisodeCard from "./EpisodeCard.svelte";
    import {
        episodes,
        page,
        filter_string,
        pagination_size,
    } from "../stores/store.js";
    import { slide } from "svelte/transition";

    $: toggle_string = $pagination_size === 8 ? "Όλα τα επεισόδια" : "Ανά 8";
    $: listing_episodes = filterEpisodes($filter_string);
    $: page_count = Math.ceil(listing_episodes.length / $pagination_size);
    $: currently_shown = listing_episodes.slice(
        $page * $pagination_size,
        $page * $pagination_size + $pagination_size
    );

    function nextPage() {
        $page += 1;
    }

    function prevPage() {
        $page -= 1;
    }

    function setPage(id) {
        $page = id;
    }

    function formatName(name) {
        return name.split(":").slice(1).join(":");
    }

    function filterEpisodes(filter) {
        if (filter) {
            $page = 0;
            let lower = filter.toLowerCase();
            return $episodes.filter(
                (e) =>
                    e.name.toLowerCase().search(lower) !== -1 ||
                    e.tags.some((tag) => tag.toLowerCase().search(lower) !== -1)
            );
        } else {
            return $episodes;
        }
    }

    function togglePages() {
        if ($pagination_size === 8) {
            $page = 0;
            $pagination_size = 500;
        } else {
            $page = 0;
            $pagination_size = 8;
        }
    }

    onMount(() => filterEpisodes($filter_string));
</script>

<div transition:slide>
    <div class="flex justify-between flex-wrap gap-1">
        <div class="btn-group order-2 md:order-1">
            <button
                class="btn border-0 opacity-70 ml-0"
                class:btn-disabled={$page === 0}
                on:click={prevPage}>«</button
            >
            {#each [...Array(page_count).keys()] as page_id}
                <button
                    class="btn border-0 opacity-70 ml-0"
                    class:bg-fuchsia-400={$page === page_id}
                    class:opacity-100={$page === page_id}
                    on:click={setPage(page_id)}
                >
                    {page_id + 1}
                </button>
            {/each}
            <button
                class="btn opacity-70 ml-0"
                class:btn-disabled={$page === page_count - 1}
                on:click={nextPage}>»</button
            >
            <button class="btn flex-grow opacity-70 ml-0" on:click={togglePages}
                >{toggle_string}</button
            >
        </div>
        <div class="order-1 md:order-2 grow md:grow-0 self-stretch">
            <input
                bind:value={$filter_string}
                placeholder="ψάξε εδώ για όλα τα θέματα"
                class="w-full h-full p-1 opacity-70 bg-neutral text-primary border-2 border-neutral focus:border-2 focus:border-fuchsia-400 outline-none"
                type="search"
            />
        </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
        {#each currently_shown as episode, i (i)}
            <EpisodeCard
                name={formatName(episode.name)}
                date={episode.date}
                image_url={episode.cover_art_url}
                number={episode.number}
                link={episode.link}
            />
        {/each}
    </div>
</div>

<style>
    .btn-group > .btn:not(:first-child) {
        margin-left: 0px;
    }
</style>
